import React from "react";
import { Link } from "gatsby";
import StatBox from "../../components/stat-box";
import PagePhoto from "../../components/page-photo";
import { graphql } from "gatsby";
import CareerLinkBox from "../../components/career-link-box";
import HeroPage from "../../components/hero-page";
import AddressCameron from "../../components/address-cameron";
import StatBoxes from "../../components/stat-boxes";
import ServiceList from "../../components/service-list";
import { postSecondary } from "../../data/post-secondary";
import { education } from "../../data/education";
import { hospitals } from "../../data/hospitals";
import { chambers } from "../../data/chambers";
import ExplorePhotos from "../../components/explore-photos";
import Seo from "../../components/seo";

const Cameron = ({ data }) => {
  const photos = data.allMdx.nodes;

  return (
    <>
      <Seo
        title="Discover PA Six: Cameron County, North Central Pennsylvania"
        description="Located in the Heart of the PA Wilds, Cameron County, in North Central Pennsylvania, is famous for its natural beauty and boasts opportunities for hunters, fishermen and nature enthusiasts."
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="Cameron County"
        caption=""
        position="25%"
      />

      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>A beautiful place to live.</h2>
            <p>
              Located in the "Heart of the PA Wilds”, this outdoor paradise is
              famous for its natural beauty and boasts opportunities for
              hunters, fishermen and nature enthusiasts.
            </p>
          </div>

          <div className="intro-text" style={{ alignSelf: "center" }}>
            <p>
              A great escape for people living the hectic rush of city life.
              Residents here call it a "beautiful place to live."
              <br />
              Cameron County was founded on March 29, 1860 from parts of McKean,
              Elk, Clearfield, Clinton and Potter counties.
              <br />
              Emporium, the county seat, was incorporated into the county
              October 13, 1864.
              <br />
              After WWI, Emporium became the home of the Incandescent Lamp
              Company later known as Sylvania Electronics.
              <br />
              Today, the county is the heart of the powdered metal industry.
            </p>
          </div>
        </section>

        <StatBoxes>
          <StatBox number="4,686" text="Total population" />
          <StatBox number="48,651" text="Per capita income" />
          <StatBox number="Emporium" text="County seat" />
        </StatBoxes>
      </main>

      <div className="county-employers">
        <section>
          <div className="employers-table-container">
            <h2>Top employers in Cameron County</h2>

            <table className="employers">
              <tr>
                <th>Employer</th>
                <th>Industry</th>
              </tr>

              <tr>
                <td>GKN Sinter Metals LLC</td>
                <td>
                  <Link to="/work/advanced-manufacturing">
                    Advanced Manufacturing
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Emporium Hardwoods</td>
                <td>
                  <Link to="/work/wood-publishing">
                    Wood, Wood Products and Publishing
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Cameron County School District</td>
                <td>
                  <Link to="/work/education">Education</Link>
                </td>
              </tr>

              <tr>
                <td>State Government</td>
                <td></td>
              </tr>

              <tr>
                <td>Embassy Powdered Metals Inc</td>
                <td>
                  <Link to="/work/advanced-manufacturing">
                    Advanced Manufacturing
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Guy and Mary Felt Manor</td>
                <td>
                  <Link to="/work/health-care">
                    Health Care and Social Assistance
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Wabtec US Rail Inc</td>
                <td>
                  <Link to="/work/advanced-manufacturing">
                    Advanced Manufacturing
                  </Link>
                </td>
              </tr>

              <tr>
                <td>County of Cameron (Local Government)</td>
                <td></td>
              </tr>

              <tr>
                <td>Keystone Rural Health Consortia Inc</td>
                <td>
                  <Link to="/work/health-care">
                    Health Care and Social Assistance
                  </Link>
                </td>
              </tr>

              <tr>
                <td>PSM BrownCo</td>
                <td>
                  <Link to="/work/advanced-manufacturing">
                    Advanced Manufacturing
                  </Link>
                </td>
              </tr>
            </table>
          </div>

          <div className="employers-photos">
            <PagePhoto
              image={data.employersImage1.sharp.image}
              caption="Alpaca Creations, Cameron County"
            />
            <PagePhoto
              image={data.employersImage2.sharp.image}
              caption="Rich Valley Apiary, Cameron County"
            />
          </div>
        </section>
      </div>

      <main>
        <CareerLinkBox
          image={data.careerImage.sharp.image}
          caption="Cameron County Chamber of Commerce"
          address=<AddressCameron />
        />

        <section>
          <h2>County Services</h2>

          <ServiceList
            title="Education"
            image={data.educationImage.sharp.fluid}
            position="40%"
            county="Cameron"
            data={education}
            cols={2}
            headingText="School"
          />

          <ServiceList
            title="Post Secondary Education"
            image={data.postSecondary.sharp.fluid}
            position="42%"
            county="Cameron"
            data={postSecondary}
            cols={2}
            headingText="School"
          />

          <div className="services-body">
            <div className="services-col">
              <ServiceList
                title="Hospitals"
                image={data.medicalImage.sharp.fluid}
                position="50%"
                county="Cameron"
                data={hospitals}
                headingText="Facility"
              />
            </div>

            <div className="services-col">
              <ServiceList
                title="Chambers of Commerce"
                image={data.businessImage.sharp.fluid}
                position="55%"
                county="Cameron"
                data={chambers}
                headingText="Service"
              />
            </div>
          </div>
        </section>

        <ExplorePhotos photos={photos} county="Cameron" />
      </main>
    </>
  );
};

export const query = graphql`
  {
    heroImage: file(relativePath: { eq: "cameron-courthouse2.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    opengraphImage: file(relativePath: { eq: "cameron-courthouse2.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(layout: FIXED, width: 1200)
      }
    }

    employersImage1: file(
      relativePath: {
        eq: "CameronCounty-AlpacaCreations-TeresaGenaux-knit-PAWilds.jpg"
      }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    employersImage2: file(
      relativePath: { eq: "CameronCounty-RichValleyApiary-PAWilds.jpg" }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    careerImage: file(relativePath: { eq: "careerlink-photo-cameron2.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    educationImage: file(
      relativePath: { eq: "neonbrand-zFSo6bnZJTw-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    postSecondary: file(
      relativePath: { eq: "clay-banks-GX8KBbVmC6c-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    medicalImage: file(
      relativePath: { eq: "olga-guryanova-tMFeatBSS4s-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    businessImage: file(
      relativePath: { eq: "arlington-research-nFLmPAf9dVc-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    # slideshow photos
    allMdx(filter: { frontmatter: { county: { eq: "cameron" } } }) {
      nodes {
        frontmatter {
          title
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`;

export default Cameron;
