import React from "react";

const AddressCameron = () => {
  return (
    <>
      <h3>
        Cameron County PA CareerLink<sup>&reg;</sup>
      </h3>
      <div>
        <p>
      134 West 4th Street
          <br />
      Emporium, PA 15834
        </p>
        <p>
          Phone:
          <span>1-844-PACALINK</span>
        </p>
      </div>
    </>
  );
};

export default AddressCameron;
